import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import {
  Title,
  SubTitle,
  Button,
  Span,
  Textarea,
  Input,
  Select,
} from "../../../StyleComponents/styles"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "CSV to SQL",
    link: "/csv-to-sql-conversion/",
  },
]
const seeAlsoArray = [
  "/json-to-csv-conversion",
  "/csv-to-json-converter",
  "/csv-to-json-converter",
  "/csv-to-tabel",
  "/text-to-csv-conversion",
  "/json-to-xml",
]

function CSVtoSQL(props) {
  let [inputCsv, setinputCsv] = useState("")
  let [resultSql, setresultSql] = useState("")
  let [csvSeparValue, setcsvSeparValue] = useState("")
  let [tabelNAME, settabelNAME] = useState("tablename")

  function inputCsvC(e) {
    setinputCsv(e.target.value)
  }
  function tabelNAMEC(e) {
    settabelNAME(e.target.value)
  }
  function csvSeparValueC(e) {
    setcsvSeparValue(e.target.value)
  }

  function CsvToSQL() {
    var results = ""
    var csv = inputCsv
    var lines = csv.split("\n")
    var head = []
    var csvseparator = csvSeparValue
    if (csvseparator === "tab") {
      csvseparator = "\t"
    }
    var tablename = tabelNAME
    var sqlcmd = "INSERT INTO " + tablename

    for (var i = 0; i < lines.length; i++) {
      if (i === 0) {
        head = lines[i].split(csvseparator)
      } else {
        if (lines[i] !== "") {
          let row = lines[i].split(csvseparator)
          var ro = ""
          for (var r = 0; r < row.length; r++) {
            if (row !== "") {
              ro += "'" + row[r] + "',"
            }
          }
          ro = ro.substring(0, ro.length - 1)
          // console.log(results)
          results += sqlcmd + "(" + head.join() + ") VALUES (" + ro + ")" + "\n"

          setresultSql(results)
        }
      }
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="CSV to SQL, Csv To SQL Conversion"
        description="CSV to SQL, CSV To SQL Conversion, Simple Converter CSV TO SQL Fast, step by step input CSV text and click convert to SQL."
        keywords={[
          "CSV to SQL Conversion, CSV to SQL, Csv To SQL, Csv To SQL Converter,Csv To SQL translator,Csv To SQL, Csv To SQL translator,convert Csv To SQL, Csv To SQL convertion, Csv To Json, Csv To SQL converter and download.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Convert CSV to SQL</Title>
        <SubTitle>CSV to SQL</SubTitle>

        <Span>Paste Your CSV Text Here:</Span>
        <Textarea value={inputCsv} onChange={inputCsvC}></Textarea>
        <div className="mb-3">
          <Span>Table name: </Span>
          <Input
            maxWidth="150px"
            type="text"
            value={tabelNAME}
            onChange={tabelNAMEC}
            aria-label="tableName"
            placeholder="TableName"
          />
        </div>
        <div className="mb-3">
          <Span>CSV Separator: </Span>
          <Select maxWidth="150px">
            <select
              aria-label="csvSeparator"
              value={csvSeparValue}
              onBlur={csvSeparValueC}
              onChange={csvSeparValueC}
            >
              <option value="tab">Tab - default</option>
              <option value=",">Comma (,)</option>
              <option value=";">Semicolon (;)</option>
            </select>
          </Select>
        </div>
        <Button
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
          className="mb-3"
          onClick={CsvToSQL}
        >
          Generate To SQL
        </Button>

        <Textarea value={resultSql} readOnly></Textarea>

        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default CSVtoSQL
